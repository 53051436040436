import styles from "../../components/processed-data/processed-data";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import functionListStyles from "../../container/Function-setup/functionList.module.scss";
import orderSummaryStyles from "../../components/orderSummary/orderSummary.module.scss";
import { useContext } from "react";
import FeatureFlagDataContext from "../../store/featureFlags";
import { minWidth } from "@mui/system";

// const showFtp = JSON.parse(
//   localStorage.getItem("ApplicationSettings")
// )?.enableFTPAutomatedOrders;
// const showReProcessBtn = JSON.parse(
//   localStorage.getItem("ApplicationSettings")
// )?.enableReprocessFiles;

const getSeverity = (Status) => {
  switch (Status) {
    case "error":
      return "danger";

    case "Success":
      return "success";
    case "Warning":
      return "warning";
    case "info":
      return "info";
    default:
      return "danger";
  }
};
const statusBodyTemplate = (rowData) => {
  return (
    <Tag
      value={rowData.errors === null ? "Success" : "Error"}
      className={styles.tag}
      icon={
        rowData.errors !== null ? "pi pi-info-circle" : "pi pi-check-circle"
      }
      severity={getSeverity(rowData.errors === null ? "Success" : "error")}
    />
  );
};
const openErrorDiaolog = (rowData, setShowErrorDialog, setErrorList) => {
  setShowErrorDialog(true);
  setErrorList(rowData?.errors);
};
const errorBodyTemplate = (rowData, setShowErrorDialog, setErrorList) => {
  return (
    <>
      {rowData.errors !== null && (
        <i
          className="pi pi-info-circle"
          style={{ cursor: "pointer" }}
          onClick={() =>
            openErrorDiaolog(rowData, setShowErrorDialog, setErrorList)
          }
        />
      )}
    </>
  );
};

export const createOrderColumns = (setShowErrorDialog, setErrorList) => [
  {
    field: "rowNumber",
    header: "Row",
    minWidth: "4rem",
    sort: true,
    frozen: true
  },
  {
    field: "function",
    header: "Status",
    minWidth: "",
    frozen: true,
    columnType: "fun",
    columnDefination: statusBodyTemplate
  },
  {
    field: "errors",
    header: "Error",
    minWidth: "6rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) =>
      errorBodyTemplate(rowData, setShowErrorDialog, setErrorList)
  },
  {
    field: "shipReqDateStr",
    header: "Ship Request Date",
    minWidth: "6rem",
    sort: true
  },
  { field: "custId", header: "Cust ID", minWidth: "6rem", sort: true },
  { field: "custName", header: "Cust Name", minWidth: "6rem", sort: true },
  { field: "wrhs", header: "WRHS", minWidth: "", sort: true },
  { field: "dstItem", header: "DST Item", minWidth: "6rem", sort: true },
  { field: "name", header: "Name", minWidth: "12rem", sort: true },
  { field: "srcItem", header: "Src Item", minWidth: "6rem", sort: true },
  { field: "uom", header: "UOM", minWidth: "6rem", sort: true },
  { field: "sun", header: "Sun", minWidth: "4rem", sort: true },
  { field: "mon", header: "Mon", minWidth: "4rem", sort: true },
  { field: "tue", header: "Tue", minWidth: "4rem", sort: true },
  { field: "wed", header: "Wed", minWidth: "4rem", sort: true },
  { field: "thu", header: "Thu", minWidth: "4rem", sort: true },
  { field: "fri", header: "Fri", minWidth: "4rem", sort: true },
  { field: "sat", header: "Sat", minWidth: "4rem", sort: true },
  { field: "total", header: "Total", minWidth: "4rem", sort: true },
  { field: "srcStore", header: "Src Store", minWidth: "6rem", sort: true },
  { field: "customerPO", header: "Customer PO", minWidth: "6rem", sort: true },
  {
    field: "purchaseList",
    header: "Purchase List",
    minWidth: "6rem",
    sort: true
  },
  {
    field: "receiptDateStr",
    header: "Receipt Date",
    minWidth: "6rem",
    sort: true
  },
  {
    field: "inventBatchID",
    header: "Inv BatchId",
    minWidth: "6rem",
    sort: true
  },
  { field: "invAccount", header: "Inv Account", minWidth: "6rem", sort: true },
  { field: "route", header: "Route", minWidth: "6rem", sort: true },
  { field: "stop", header: "Stop", minWidth: "6rem", sort: true }
];

const functionStatusBodyTemplate = (
  rowData,
  navigateToFunctionForm,
  deleteFunction
) => {
  const showAction =
    rowData?.loggedInUserRoleId === 1
      ? true
      : rowData?.loggedInUserRoleId !== 1 && rowData?.functionRoleId === 4
      ? true
      : false;
  const showView =
    rowData?.loggedInUserRoleId === 1
      ? true
      : rowData?.loggedInUserRoleId !== 1 && rowData?.functionRoleId === 4
      ? true
      : rowData?.loggedInUserRoleId !== 1 && rowData?.functionRoleId === 5
      ? true
      : false;
  return (
    <>
      {showAction && (
        <Button
          icon="pi pi-pencil"
          severity="warning"
          aria-label="Edit"
          className={`${functionListStyles.editBtn} ${functionListStyles.btns} `}
          onClick={() => navigateToFunctionForm("edit", rowData?.functionId)}
        />
      )}
      {showView && (
        <Button
          icon="pi pi-eye"
          severity="warning"
          aria-label=""
          className={`${functionListStyles.viewBtn} ${functionListStyles.btns} `}
          onClick={() => navigateToFunctionForm("view", rowData?.functionId)}
        />
      )}
      {showAction && (
        <Button
          icon="pi pi-trash"
          severity="warning"
          aria-label="Delete"
          className={`${functionListStyles.dltBtn} ${functionListStyles.btns} `}
          onClick={() => deleteFunction(rowData?.functionId)}
        />
      )}
    </>
  );
};

export const Functioncolumns = (
  navigateToFunctionForm,
  deleteFunction,
  enableFTPAutomatedOrders
) => {
  const columns = [
    { field: "regionName", header: "Region", minWidth: "8rem", sort: true },
    {
      field: "functionName",
      header: "Function",
      minWidth: "8rem",
      sort: true
    },
    {
      field: "createdDate",
      header: "Created Date",
      minWidth: "10rem",
      sort: true
    },
    { field: "createdBy", header: "Created By", minWidth: "9rem", sort: true },
    { field: "status", header: "Status", minWidth: "6rem", sort: true },
    {
      field: "function",
      header: "Actions",
      minWidth: "8rem",
      sort: false,
      columnType: "fun",
      columnDefination: (rowData) =>
        functionStatusBodyTemplate(
          rowData,
          navigateToFunctionForm,
          deleteFunction
        )
    }
  ];

  if (enableFTPAutomatedOrders) {
    columns.splice(columns?.length - 1, 1);
    columns.push({
      field: "functionType",
      header: "Function Type",
      minWidth: "10rem",
      sort: true
    });
    columns.push({
      field: "function",
      header: "Actions",
      minWidth: "8rem",
      sort: false,
      columnType: "fun",
      columnDefination: (rowData) =>
        functionStatusBodyTemplate(
          rowData,
          navigateToFunctionForm,
          deleteFunction
        )
    });
  }

  return columns;
};

const expectedQtyTemplate = (rowData) => {
  return (
    <Tag
      className={orderSummaryStyles.tag}
      severity={getSeverity(
        rowData?.color === 1
          ? "Success"
          : rowData?.color === 2
          ? "info"
          : rowData?.color === 3
          ? "error"
          : "Warning"
      )}
    >
      <div className="flex align-items-center gap-2">
        {rowData?.isAcceptableRange ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M9.49935 11.833H1.75268C1.33417 11.833 0.932605 11.6676 0.635427 11.373C0.33825 11.0783 0.169533 10.6781 0.166016 10.2596V6.49962C0.167779 6.07997 0.335723 5.67811 0.63309 5.38199C0.930457 5.08587 1.33302 4.91962 1.75268 4.91962H3.08602L4.90602 0.859624C5.03033 0.582124 5.25807 0.364123 5.54074 0.252053C5.8234 0.139982 6.13866 0.142699 6.41935 0.259624C6.79448 0.425812 7.11292 0.697926 7.33557 1.04256C7.55822 1.38719 7.67538 1.78934 7.67268 2.19962V3.84629H10.3393C10.3769 3.83995 10.4152 3.83995 10.4527 3.84629C10.8753 3.92775 11.2515 4.16587 11.506 4.51296C11.7464 4.83361 11.8517 5.23564 11.7993 5.63296L11.066 10.493C11.0073 10.8663 10.8172 11.2064 10.53 11.4521C10.2428 11.6977 9.87729 11.8328 9.49935 11.833V11.833ZM3.87935 10.833H9.49935C9.63829 10.8348 9.77326 10.7866 9.87971 10.6973C9.98616 10.608 10.057 10.4834 10.0793 10.3463L10.826 5.50629C10.8352 5.44007 10.8305 5.37265 10.8121 5.30836C10.7937 5.24407 10.7621 5.18433 10.7193 5.13296C10.6111 4.98977 10.4549 4.89037 10.2793 4.85296H7.16602C7.09953 4.85388 7.03355 4.84136 6.97203 4.81613C6.91051 4.79091 6.85473 4.75352 6.80802 4.70619C6.76132 4.65887 6.72467 4.60259 6.70027 4.54074C6.67586 4.4789 6.66421 4.41276 6.66602 4.34629V2.19962C6.66691 1.98302 6.60422 1.77092 6.48571 1.58961C6.36721 1.40829 6.1981 1.26574 5.99935 1.17962C5.96041 1.16413 5.91695 1.16453 5.87831 1.18073C5.83966 1.19694 5.80892 1.22766 5.79268 1.26629L3.87935 5.52629V10.833ZM1.75268 5.91962C1.67596 5.91874 1.59982 5.93309 1.52868 5.96185C1.45754 5.9906 1.39281 6.03318 1.33824 6.08713C1.28368 6.14107 1.24036 6.20531 1.21079 6.27612C1.18123 6.34692 1.16601 6.42289 1.16602 6.49962V10.2596C1.16689 10.3358 1.18275 10.411 1.21271 10.4811C1.24266 10.5511 1.28612 10.6146 1.3406 10.6678C1.39507 10.721 1.45951 10.763 1.53021 10.7914C1.60092 10.8197 1.67651 10.8338 1.75268 10.833H2.87935V5.91962H1.75268Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            style={{ transform: "rotateX(180deg)" }}
          >
            <path
              d="M9.49935 11.833H1.75268C1.33417 11.833 0.932605 11.6676 0.635427 11.373C0.33825 11.0783 0.169533 10.6781 0.166016 10.2596V6.49962C0.167779 6.07997 0.335723 5.67811 0.63309 5.38199C0.930457 5.08587 1.33302 4.91962 1.75268 4.91962H3.08602L4.90602 0.859624C5.03033 0.582124 5.25807 0.364123 5.54074 0.252053C5.8234 0.139982 6.13866 0.142699 6.41935 0.259624C6.79448 0.425812 7.11292 0.697926 7.33557 1.04256C7.55822 1.38719 7.67538 1.78934 7.67268 2.19962V3.84629H10.3393C10.3769 3.83995 10.4152 3.83995 10.4527 3.84629C10.8753 3.92775 11.2515 4.16587 11.506 4.51296C11.7464 4.83361 11.8517 5.23564 11.7993 5.63296L11.066 10.493C11.0073 10.8663 10.8172 11.2064 10.53 11.4521C10.2428 11.6977 9.87729 11.8328 9.49935 11.833V11.833ZM3.87935 10.833H9.49935C9.63829 10.8348 9.77326 10.7866 9.87971 10.6973C9.98616 10.608 10.057 10.4834 10.0793 10.3463L10.826 5.50629C10.8352 5.44007 10.8305 5.37265 10.8121 5.30836C10.7937 5.24407 10.7621 5.18433 10.7193 5.13296C10.6111 4.98977 10.4549 4.89037 10.2793 4.85296H7.16602C7.09953 4.85388 7.03355 4.84136 6.97203 4.81613C6.91051 4.79091 6.85473 4.75352 6.80802 4.70619C6.76132 4.65887 6.72467 4.60259 6.70027 4.54074C6.67586 4.4789 6.66421 4.41276 6.66602 4.34629V2.19962C6.66691 1.98302 6.60422 1.77092 6.48571 1.58961C6.36721 1.40829 6.1981 1.26574 5.99935 1.17962C5.96041 1.16413 5.91695 1.16453 5.87831 1.18073C5.83966 1.19694 5.80892 1.22766 5.79268 1.26629L3.87935 5.52629V10.833ZM1.75268 5.91962C1.67596 5.91874 1.59982 5.93309 1.52868 5.96185C1.45754 5.9906 1.39281 6.03318 1.33824 6.08713C1.28368 6.14107 1.24036 6.20531 1.21079 6.27612C1.18123 6.34692 1.16601 6.42289 1.16602 6.49962V10.2596C1.16689 10.3358 1.18275 10.411 1.21271 10.4811C1.24266 10.5511 1.28612 10.6146 1.3406 10.6678C1.39507 10.721 1.45951 10.763 1.53021 10.7914C1.60092 10.8197 1.67651 10.8338 1.75268 10.833H2.87935V5.91962H1.75268Z"
              fill="white"
            />
          </svg>
        )}
        <span style={{ fontWeight: "12px" }}>
          {rowData?.itemRangeDescription}
        </span>
      </div>
    </Tag>
  );
};
const showxmlfile = (rawData, showModal) => {
  return (
    <>
      {rawData?.fileUrl !== null ? (
        <i
          className={`pi pi-file ${functionListStyles.fileIcon}`}
          onClick={(e) => {
            showModal(rawData);
          }}
        ></i>
      ) : (
        ""
      )}
    </>
  );
};

const showReProcess = (rowData, reprocess, proceed) => {
  return (
    <>
      {rowData.isFileEligibleForReprocess && (
        <Button
          label="ReProcess"
          icon="pi pi-replay"
          className={`${functionListStyles.reprocessBtn}`}
          onClick={(e) => {
            reprocess(rowData);
          }}
        />
      )}
      {rowData.isEligibleForProceed && (
        <Button
          label="Proceed"
          className={`${functionListStyles.prpceedBtn}`}
          onClick={(e) => {
            proceed(rowData);
          }}
        />
      )}
    </>
  );
};

const showlineItems = (rowData, lineItems) => {
  return (
    <>
      {rowData?.fileUrl == null && (
        <Button
          label="View Details"
          icon="pi pi-info-circle"
          className={`${functionListStyles.reprocessBtn} }`}
          onClick={(e) => lineItems(rowData?.lineItems)}
        />
      )}
    </>
  );
};

export const orderSummaryColumns = [
  { field: "itemId", header: "AX Item", minWidth: "8rem", sort: true },
  { field: "description", header: "Name", minWidth: "10rem", sort: true },
  { field: "quantity", header: "Qty Order", minWidth: "10rem", sort: true },
  {
    field: "itemTolerancePercentage",
    header: "Tolerance %",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "isAcceptableRange",
    header: "% of Expected Qty",
    minWidth: "12rem",
    sort: false,
    columnType: "fun",
    columnDefination: (rowData) => expectedQtyTemplate(rowData)
  }
];

const formatDateFromUTCtoLocal = (rowdata, flag) => {
  // UTC date and time strings
  let utcDateString = rowdata?.orderDate;
  let utcTimeString = rowdata?.orderTime;

  // Combine date and time into a single UTC string
  let utcDateTimeString = `${utcDateString} ${utcTimeString} UTC`;

  // Create a Date object from the combined UTC string
  let utcDate = new Date(utcDateTimeString);

  // Convert to local system date and time
  let localDateTime = utcDate.toLocaleString();

  console.log(localDateTime);
  if (flag == "date") {
    return localDateTime.split(",")[0];
  } else if (flag == "time") {
    return localDateTime.split(",")[1];
  }
};
export const logSummaryColumns = [
  {
    field: "successFiles",
    header: "Results",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) =>
      `Completed ${rowData.successFiles}/${rowData.numberOfFiles}`
  },
  { field: "functionName", header: "Function", minWidth: "4rem", sort: true },
  {
    field: "orderDate",
    header: "Date",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) => formatDateFromUTCtoLocal(rowData, "date")
  },
  {
    field: "orderTime",
    header: "Time",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) => formatDateFromUTCtoLocal(rowData, "time")
  },
  {
    field: "createdBy",
    header: "Who",
    minWidth: "4rem",
    sort: true
  },
  {
    field: "numberOfFiles",
    header: "# Items",
    minWidth: "4rem",
    sort: true
  }
];

export const autoLogSummaryColumns = [
  {
    field: "successFiles",
    header: "Results",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) =>
      `Completed ${rowData.successFiles}/${rowData.numberOfFiles}`
  },
  { field: "functionName", header: "Function", minWidth: "4rem", sort: true },
  {
    field: "orderDate",
    header: "Date",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) => formatDateFromUTCtoLocal(rowData, "date")
  },
  {
    field: "orderTime",
    header: "Time",
    minWidth: "4rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData) => formatDateFromUTCtoLocal(rowData, "time")
  },
  {
    field: "rawFileName",
    header: "File Name",
    minWidth: "4rem",
    sort: true
  },
  {
    field: "numberOfFiles",
    header: "# Items",
    minWidth: "4rem",
    sort: true
  }
];

export const rowExpansionLogSummaryColumns = (
  screenFlag,
  reProcessItem,
  proceed,
  showReprocessbtn
) => {
  const columns = [];
  columns.push(
    { field: "createdTime", header: "Time", minWidth: "4rem", sort: true },
    {
      field: "shipReqDate",
      header: "Ship request date",
      minWidth: "6rem",
      sort: true
    },
    { field: "customerId", header: "Cust ID", minWidth: "4rem", sort: true },
    { field: "itemQty", header: "Items Qty", minWidth: "4rem", sort: true },
    {
      field: "poNumber",
      header: "PO#",
      minWidth: "4rem",
      sort: true
    },
    {
      field: "fileName",
      header: "XML File",
      minWidth: "4rem",
      sort: true,
      columnType: "fun",
      columnDefination: (rowData, setVisible) =>
        showxmlfile(rowData, setVisible)
    },
    {
      field: "status",
      header: "Status",
      minWidth: "4rem",
      sort: true
    },
    {
      field: "errorMsg",
      header: "Error",
      minWidth: "4rem",
      sort: true
    },
    {
      field: "",
      header: "Action",
      minWidth: "4rem",
      sort: false,
      columnType: "fun",
      columnDefination: (rowData) =>
        showReProcess(rowData, reProcessItem, proceed)
    }
  );
  if (!showReprocessbtn) {
    columns?.splice(columns?.length - 1, 1);
  }
  if (screenFlag == "showAuto") {
    columns.splice(7, 0, {
      field: "",
      header: "Line Items",
      minWidth: "6rem",
      sort: true,
      columnType: "fun",
      columnDefination: (rowData, setxml, setShowLineItems) =>
        showlineItems(rowData, setShowLineItems)
    });
  }
  return columns;
};
export const rowExpansionLogSummaryColumnsErrors = [
  {
    field: "rowNumber",
    header: "Row",
    minWidth: "4rem",
    sort: true,
    frozen: true
  },
  {
    field: "function",
    header: "Status",
    minWidth: "",
    frozen: true,
    columnType: "fun",
    columnDefination: statusBodyTemplate
  },
  {
    field: "errors",
    header: "Error",
    minWidth: "6rem",
    sort: true,
    columnType: "fun",
    columnDefination: (rowData, setShowErrorDialog, setErrorList) =>
      errorBodyTemplate(rowData, setShowErrorDialog, setErrorList)
  },
  {
    field: "shipReqDateStr",
    header: "Ship Request Date",
    minWidth: "6rem",
    sort: true
  },
  { field: "custId", header: "Cust ID", minWidth: "6rem", sort: true },
  { field: "custName", header: "Cust Name", minWidth: "6rem", sort: true },
  { field: "wrhs", header: "WRHS", minWidth: "", sort: true },
  { field: "dstItem", header: "DST Item", minWidth: "6rem", sort: true },
  { field: "name", header: "Name", minWidth: "12rem", sort: true },
  { field: "srcItem", header: "Src Item", minWidth: "6rem", sort: true },
  { field: "uom", header: "UOM", minWidth: "6rem", sort: true },
  { field: "sun", header: "Sun", minWidth: "4rem", sort: true },
  { field: "mon", header: "Mon", minWidth: "4rem", sort: true },
  { field: "tue", header: "Tue", minWidth: "4rem", sort: true },
  { field: "wed", header: "Wed", minWidth: "4rem", sort: true },
  { field: "thu", header: "Thu", minWidth: "4rem", sort: true },
  { field: "fri", header: "Fri", minWidth: "4rem", sort: true },
  { field: "sat", header: "Sat", minWidth: "4rem", sort: true },
  { field: "total", header: "Total", minWidth: "4rem", sort: true },
  { field: "srcStore", header: "Src Store", minWidth: "6rem", sort: true },
  { field: "customerPO", header: "Customer PO", minWidth: "6rem", sort: true },
  {
    field: "purchaseList",
    header: "Purchase List",
    minWidth: "6rem",
    sort: true
  },
  {
    field: "receiptDateStr",
    header: "Receipt Date",
    minWidth: "6rem",
    sort: true
  },
  {
    field: "inventBatchID",
    header: "Inv BatchId",
    minWidth: "6rem",
    sort: true
  },
  { field: "invAccount", header: "Inv Account", minWidth: "6rem", sort: true },
  { field: "route", header: "Route", minWidth: "6rem", sort: true },
  { field: "stop", header: "Stop", minWidth: "6rem", sort: true }
];

export const ediFilePurchaseOrderColumns = [
  {
    field: "poNumber",
    header: "PO#",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "deliveryDate",
    header: "PO Created Date",
    minWidth: "12rem",
    sort: true
  },
  {
    field: "vendorSequenceNumber",
    header: "Vendor Sequence Number",
    minWidth: "16rem",
    sort: true
  },
  {
    field: "distributionCenterNumber",
    header: "D. C. Number",
    minWidth: "12rem",
    sort: true
  },
  {
    field: "actualFulfillmentRegion",
    header: "Region",
    minWidth: "16rem",
    sort: true
  },
  {
    field: "processingStatus",
    header: "Status",
    minWidth: "12rem",
    sort: true
  },
  {
    field: "buyingPartyName",
    header: "Buying Party",
    minWidth: "12rem",
    sort: true
  },
  {
    field: "supplierName",
    header: "Supplier",
    minWidth: "12rem",
    sort: true
  },
  {
    field: "ediFileCreatedDateUTC",
    header: "Edi File Created Date UTC",
    minWidth: "16rem",
    sort: true
  },
  {
    field: "ediFileName",
    header: "Edi File Name",
    minWidth: "12rem",
    sort: true
  }
];

export const rowExpansionEdiLineItemsColumns = [
  {
    field: "itemDescription",
    header: "Item Description",
    minWidth: "15rem",
    sort: true
  },
  {
    field: "buyersItemId",
    header: "Buyers Item Id",
    minWidth: "10rem",
    sort: true
  },
  {
    field: "sellersItemId",
    header: "Sellers Item Id",
    minWidth: "60rem",
    sort: true
  }
];

export const ediFilePurchaseOrderReProcessColumns = [
  {
    field: "poNumber",
    header: "PO#",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "vendorSequenceNumber",
    header: "Vendor Sequence Number",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "distributionCenterNumber",
    header: "D. C. Number",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "actualFulfillmentRegion",
    header: "Region",
    minWidth: "8rem",
    sort: true
  }
];

export const ediDuplicateColumns = [
  {
    field: "purchaseOrderNumber",
    header: "PO#",
    minWidth: "8rem",
    sort: true
  },
  {
    field: "deliveryDate",
    header: "PO Created Date",
    minWidth: "4rem",
    sort: true
  },
  {
    field: "processingStatus",
    header: "Status",
    minWidth: "4rem",
    sort: true
  }
];
