import { useState, useEffect, useRef,useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import {
  functionSetup,
  functionSetupEdit,
  FunctionTestConnection
} from "../../services/functionsetup";

import styles from "./functionForm.module.scss";
import globalStyles from "./functionSetup.module.scss";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";

import { Formik, Form } from "formik";
import * as YUP from "yup";
import GeneralInfoForm from "./generalInfoForm";
import UserInfoForm from "./userInfoForm";
import FTPForm from "./ftpInfoForm";
import MapFileInfoForm from "./mapFileInfoForm";
import Spinner from "../../components/spinner/spinner";
import { unstable_usePrompt } from "react-router-dom";
import FeatureFlagDataContext from "../../store/featureFlags";
import { appInsights } from '../../AppInsights';

const initialValue = {
  status: null,
  region: null,
  customer: null,
  function: null,
  mapToUse: null,
  poMode: null,
  poNum:null,
  shipReqDate:null,
  timezone:null,
  maxQuantity: 2000,
  offShipdays: 0,
  allowedPasteDays: 0,
  warningDays: 0,
  tolerance: 90,
  offsetreceiptDays: 0,
  ediextraComment: null,
  includeRoutes: false,
  includeItemXRef: true,
  allowNoSalesline: false,
  createPOCheck: false,
  usersList: null,
  adminUsersList: null,
  emailList: "",
  dataSource: null,
  AIFDirectory: null,
  copyFrom: null,
  storeInHeaderRow: null,
  storeInHeaderColumn: null,
  storeInHeaderLabel: null,
  dateInHeaderRow: null,
  dateInHeaderColumn: null,
  dateInHeaderLabel: null,
  poInHeaderRow: null,
  poInHeaderColumn: null,
  poInHeaderLabel: null,
  loadDescription: false,
  loadWRHS: false,
  allowDuplicates: false,
  multiStore: false,
  dunnPrefix: null,
  dunnSufix: null,
  itemXref: null,
  defaultWRHS: null,
  suffixPrefix: null,
  shipToXreflength: null,
  tabularHeaderStartsRow: 0,
  keywords: null,
  colShipReq: null,
  colReceiptDate: null,
  colShipTo: null,
  colPO: null,
  colItem: null,
  colQty: null,
  colUOM: null,
  colDescription: null,
  colRoute: null,
  colStop: null,
  colWRHS: null,
  colInvBatchID: null,
  colPurchaseOrder: null,
  headerNameShipReq: null,
  headerNameReceiptDate: null,
  headerNameShipTo: null,
  headerNamePO: null,
  headerNameItem: null,
  headerNameQty: null,
  headerNameUOM: null,
  headerNameDescription: null,
  headerNameRoute: null,
  headerNameStop: null,
  headerNameWRHS: null,
  headerNameInvBatchID: null,
  headerNamePurchaseOrder: null,
  altFirstHeaderShipReq: null,
  altFirstHeaderReceiptDate: null,
  altFirstHeaderShipTo: null,
  altFirstHeaderPO: null,
  altFirstHeaderItem: null,
  altFirstHeaderQty: null,
  altFirstHeaderUOM: null,
  altFirstHeaderDescription: null,
  altFirstHeaderRoute: null,
  altFirstHeaderStop: null,
  altFirstHeaderWRHS: null,
  altFirstHeaderInvBatchID: null,
  altFirstHeaderPurchaseOrder: null,
  altSecondHeaderShipReq: null,
  altSecondHeaderReceiptDate: null,
  altSecondHeaderShipTo: null,
  altSecondHeaderPO: null,
  altSecondHeaderItem: null,
  altSecondHeaderQty: null,
  altSecondHeaderUOM: null,
  altSecondHeaderDescription: null,
  altSecondHeaderRoute: null,
  altSecondHeaderStop: null,
  altSecondHeaderWRHS: null,
  altSecondHeaderInvBatchID: null,
  altSecondHeaderPurchaseOrder: null,
  fileStartsWith: null,
  userId: null,
  site: null,
  password: null,
  sourceDir: null,
  ediArchDir: null,
  archiveFilesCheck: false,
  disableftp: false,
  ftpEmailList: null,
  source: null,
  storageAccName:null,
  storageContName: null,
  pathToFiles:null,
  integrationtype:null,
  archiveContainerName:null,
  blobArchPath: null,
  blobarchiveFilesCheck:false,
  fileNamePrefix: null,
  backUpFileNamePrefix:null,
  blobEmailList: null,
  clientid: null,
  tenantid: null,
  clientSecret: null,
  disableblob: false
};

const FunctionForm = (props) => {
  const formRef = useRef(null);
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);
  const formState = props?.formState;
  const functionId = props?.functionId;
  const toast = useRef(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isReadonly, setIsReadonly] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSpinner, setShowSpinner] = useState(true);
  const [pageHeading, setPageHeading] = useState("Add Function");
  const [isFTPFilled, setIsFTPFilled] = useState(false);
  const [isBlobFilled, setIsBlobFilled] = useState(false);
  const [showPoNumberField, setShowPONumberField] = useState(false);
  const [isTimeZoneMandatory, setIsTimeZoneMandatory] = useState(false);
  const [showBackupPrefix, setShowBackupprefix] = useState(false);
  const [status, setStatus] = useState([]);
  const dispatch = useDispatch();
  // const showFtp = JSON.parse(localStorage.getItem('ApplicationSettings'))?.enableFTPAutomatedOrders
  const showFtp = featureFlags?.enableFTPAutomatedOrders

  const { getFunctionMasterData, getFunctionDetailas } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const masterDataForFunction = useSelector((state) => {
    return state.orderReducer.masterData;
  });
  const functionDetailsByIdData = useSelector((state) => {
    return state.orderReducer.functionDetailsById;
  });

  useEffect(() => {
   
    const fetchData = async () => {
      setShowSpinner(true);
      await fetchMasterData();
    };
    fetchData();
  }, [functionId]);


  useEffect(() => {
    (async () => {
      if (masterDataForFunction?.statusMapping) {
        if (formState.trim()?.toLocaleLowerCase() === "new") {
          setPageHeading("Add Function");
          setIsReadonly(false);
          const statusData =
            masterDataForFunction?.statusMapping?.sourceDestinationStatusMappings.filter(
              (item) => item?.currentStatus.trim() === ""
            );
          setStatus(statusData[0]?.availableStatuses);
          formRef?.current?.setFieldValue(
            "status",
            statusData[0]?.availableStatuses?.[0]
          );
        } else if (
          formState.trim()?.toLocaleLowerCase() === "edit" &&
          functionId !== 0
        ) {
          setPageHeading("Edit Function");
          setIsReadonly(false);
          await fetchFunctionData(functionId);
        } else if (
          formState.trim()?.toLocaleLowerCase() === "view" &&
          functionId !== 0
        ) {
          setPageHeading("View Function");
          setIsReadonly(true);
          await fetchFunctionData(functionId);
        } else {
          setStatus(masterDataForFunction?.statusMapping?.inactive);
        }
      }
    })();
  }, [masterDataForFunction, formRef]);

  useEffect(()=>{
    if(functionId !== 0 && Object.keys(masterDataForFunction).length > 0  && Object.keys(functionDetailsByIdData).length > 0 ){
      setTimeout(() => {
        setShowSpinner(false)
      }, 1200);
    }
    else if( Object.keys(masterDataForFunction).length > 0){
      setTimeout(() => {
        setShowSpinner(false)
      }, 1200);
    }
  },[masterDataForFunction,functionDetailsByIdData, functionId])

  useEffect(() => {
    (async () => {
      if (
        functionId !== 0 &&
        functionDetailsByIdData?.functionId === functionId &&
        Object.keys(functionDetailsByIdData).length
      )
        await prepopulateData(functionDetailsByIdData);
    })();
  }, [functionDetailsByIdData]);
  const fetchMasterData = async () => {
    try {
      getFunctionMasterData();
    } catch (error) {
      setShowSpinner(false)
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while fetching Function Master data",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const resetBlob = () => {
    formRef?.current?.setFieldValue("storageAccName", null)
    formRef?.current?.setFieldValue("storageContName", null);
    formRef?.current?.setFieldValue("pathToFiles", null);
    formRef?.current?.setFieldValue("archiveContainerName",null);
    formRef?.current?.setFieldValue("blobArchPath", null);
    formRef?.current?.setFieldValue("fileNamePrefix", null);
    formRef?.current?.setFieldValue("blobarchiveFilesCheck", false);
    formRef?.current?.setFieldValue("blobEmailList", null);
    formRef?.current?.setFieldValue("disableblob", false);
    formRef?.current?.setFieldValue("clientid", null);
    formRef?.current?.setFieldValue("tenantid", null);
    formRef?.current?.setFieldValue("clientSecret", null);
  };

  const resetFTP =() =>{
    formRef?.current?.setFieldValue("site", null)
    formRef?.current?.setFieldValue("ftpEmailList", null);
    formRef?.current?.setFieldValue("userId", null);
    formRef?.current?.setFieldValue("password", null);
    formRef?.current?.setFieldValue("sourceDir", null);
    formRef?.current?.setFieldValue("ediArchDir", null);
    formRef?.current?.setFieldValue("archiveFilesCheck", false);
    formRef?.current?.setFieldValue("fileStartsWith", null);
    formRef?.current?.setFieldValue("disableftp", false);
  }
  
  const fetchFunctionData = async (id) => {
    try {
      await getFunctionDetailas(id);
    } catch (error) {
      setShowSpinner(false)
    }
  };

  const prepopulateData = (formDetails) => {
    if (
      formState.trim()?.toLocaleLowerCase() === "edit" &&
      functionId !== 0 
    ){
      setPageHeading(`Edit Function`)
    }
    else if( formState.trim()?.toLocaleLowerCase() === "view" &&
    functionId !== 0 ){
      setPageHeading(`View Function`)
    }
    
    formRef?.current?.resetForm();
    const statusData =
      masterDataForFunction?.statusMapping?.sourceDestinationStatusMappings.find(
        (item) =>
          item?.currentStatus?.trim()?.toLocaleLowerCase() ===
          formDetails?.status?.trim()?.toLocaleLowerCase()
      );
    setStatus(statusData?.availableStatuses);
    const regionData = masterDataForFunction?.regions.filter(
      (item) => item?.regionId === formDetails?.regionId
    );
    const customer = masterDataForFunction?.customers.filter((customer) =>
      formDetails?.customerIds.includes(customer.customerId)
    );
    const mapToUse = masterDataForFunction?.fileFormats.filter(
      (item) => item?.fileFormatId === formDetails?.fileFormatId
    );
    const poMode = masterDataForFunction?.poModes.filter(
      (item) => item?.poModeId === formDetails?.poModeId
    );
    const timeZone = masterDataForFunction?.timezones.filter(
      (item) => item?.timezoneId === formDetails?.timezoneId
    );
    const status = statusData?.availableStatuses?.filter(
      (item) => item?.id == formDetails?.statusId
    );
    if(poMode[0]?.poModeId == 6){
      setShowPONumberField(true)
    }
    else{
      setShowPONumberField(false)
    }

    if(formDetails?.doesShipRequestDateUseCurrentDate){
      formRef?.current?.setFieldValue("shipReqDate",{label:"Current Date", id:2})
      setIsTimeZoneMandatory(true)
    }
    
    formRef?.current?.setFieldValue("status", status?.[0]);
    formRef?.current?.setFieldValue("region", regionData[0]);
    formRef?.current?.setFieldValue("customer", customer);
    formRef?.current?.setFieldValue("function", formDetails?.functionName);
    formRef?.current?.setFieldValue("mapToUse", mapToUse[0]);
    formRef?.current?.setFieldValue("poMode", poMode[0]);
    formRef?.current?.setFieldValue("poNum", formDetails?.configuredPoNumber)
    formRef?.current?.setFieldValue("timezone", timeZone[0])
    formRef?.current?.setFieldValue("users", formDetails.users);
    formRef?.current?.setFieldValue("adminUsers", formDetails.adminUsers);
    formRef?.current?.setFieldValue(
      "AIFDirectory",
      formDetails.outBoundDirectory
    );
    formRef?.current?.setFieldValue(
      "emailList",
      formDetails.emailList?.map((email) => email.name)?.join(", ")
    );
    formRef?.current?.setFieldValue("dataSource", formDetails?.dataSource);
    formRef?.current?.setFieldValue("maxQuantity", formDetails?.maxItemQty);
    formRef?.current?.setFieldValue("offShipdays", formDetails?.offsetShipDays);
    formRef?.current?.setFieldValue(
      "allowedPasteDays",
      formDetails?.allowedPastDays
    );
    formRef?.current?.setFieldValue("warningDays", formDetails?.warningDays);
    formRef?.current?.setFieldValue("tolerance", formDetails?.tolerance);
    formRef?.current?.setFieldValue(
      "offsetreceiptDays",
      formDetails?.offsetReceiptDays
    );
    formRef?.current?.setFieldValue(
      "ediextraComment",
      formDetails?.ediExtraComments
    );
    formRef?.current?.setFieldValue(
      "includeRoutes",
      formDetails?.includeRoutesOrStops
    );
    formRef?.current?.setFieldValue(
      "includeItemXRef",
      formDetails?.includeItemXref
    );
    formRef?.current?.setFieldValue(
      "allowNoSalesline",
      formDetails?.allowNoSalesLines
    );
    formRef?.current?.setFieldValue("createPOCheck", formDetails?.createPOCSV);

    formRef?.current?.setFieldValue(
      "storeInHeaderRow",
      formDetails?.storeInHeaderRow
    );
    formRef?.current?.setFieldValue(
      "storeInHeaderColumn",
      formDetails?.storeInHeaderColumn
    );
    formRef?.current?.setFieldValue(
      "storeInHeaderLabel",
      formDetails?.storeLabel
    );
    formRef?.current?.setFieldValue(
      "dateInHeaderRow",
      formDetails?.dateInHeaderRow
    );
    formRef?.current?.setFieldValue(
      "dateInHeaderColumn",
      formDetails?.dateInHeaderColumn
    );
    formRef?.current?.setFieldValue(
      "dateInHeaderLabel",
      formDetails?.dateLabel
    );
    formRef?.current?.setFieldValue(
      "poInHeaderRow",
      formDetails?.poinHeaderRow
    );
    formRef?.current?.setFieldValue(
      "poInHeaderColumn",
      formDetails?.poinHeaderColumn
    );
    formRef?.current?.setFieldValue("poInHeaderLabel", formDetails?.polabel);
    formRef?.current?.setFieldValue(
      "loadDescription",
      formDetails?.loadDescription
    );
    formRef?.current?.setFieldValue(
      "loadWRHS",
      formDetails?.loadWrhssalesHeader
    );
    formRef?.current?.setFieldValue(
      "allowDuplicates",
      formDetails?.allowDuplicateItems
    );
    formRef?.current?.setFieldValue("dunnPrefix", formDetails?.dunnPrefix);
    formRef?.current?.setFieldValue("dunnSufix", formDetails?.dunnSufix);
    formRef?.current?.setFieldValue("itemXref", formDetails?.itemXrefLenght);
    formRef?.current?.setFieldValue("defaultWRHS", formDetails?.defaultWrhs);
    formRef?.current?.setFieldValue(
      "tabularHeaderStartsRow",
      formDetails?.dataStartRow
    );
    formRef?.current?.setFieldValue("keywords", formDetails?.keywords);
    if(formDetails?.functionTypeId == 2){
      formRef?.current?.setFieldValue(
        "source",
        { label: "FTP", id: 2 }
      );
      formRef?.current?.setFieldValue(
        "site",
        formDetails?.ftpSettings?.ftpSite
      );
      formRef?.current?.setFieldValue(
        "ftpEmailList",
        formDetails.ftpEmailList?.map((email) => email.name)?.join(", ")
      );
      formRef?.current?.setFieldValue(
        "userId",
        formDetails?.ftpSettings?.ftpUserName
      );
      formRef?.current?.setFieldValue(
        "password",
        formDetails?.ftpSettings?.ftpPassword
      );
      formRef?.current?.setFieldValue(
        "sourceDir",
        formDetails?.ftpSettings?.ftpSourcePath
      );
      formRef?.current?.setFieldValue(
        "ediArchDir",
        formDetails?.ftpSettings?.ftpArchivePath
      );
      formRef?.current?.setFieldValue(
        "archiveFilesCheck",
        formDetails?.ftpSettings?.shouldArchive
      );
      formRef?.current?.setFieldValue(
        "fileStartsWith",
        formDetails?.ftpSettings?.fileNameStartsWith
      );
      if(formDetails?.ftpSettings?.isActive == false){
        formRef?.current?.setFieldValue(
          "disableftp",
         true
        );
      }
      else{
        formRef?.current?.setFieldValue(
          "disableftp",
          false
        );
      }
    
    }
    if(formDetails?.functionTypeId == 3){
      formRef?.current?.setFieldValue(
        "source",
        { label: "External BLOB", id: 3 }
      );
      formRef?.current?.setFieldValue(
        "storageAccName",
        formDetails?.externalBlobSettings?.blobAccountName
      );
      formRef?.current?.setFieldValue(
        "storageContName",
        formDetails?.externalBlobSettings?.blobContainerName
      );
      formRef?.current?.setFieldValue(
        "pathToFiles",
        formDetails?.externalBlobSettings?.blobSourcePath
      );
      formRef?.current?.setFieldValue(
        "archiveContainerName",
        formDetails?.externalBlobSettings?.archiveContainerName
      );
      formRef?.current?.setFieldValue(
        "blobArchPath",
        formDetails?.externalBlobSettings?.blobArchivePath
      );
      formRef?.current?.setFieldValue(
        "fileNamePrefix",
        formDetails?.externalBlobSettings?.fileNameStartsWith
      );
      formRef?.current?.setFieldValue(
        "backUpFileNamePrefix",
        formDetails?.externalBlobSettings?.backupFileNamePrefix
      );
      if(formDetails?.externalBlobSettings?.isIntegrationTypeRelex == true){
        setShowBackupprefix(true)
        formRef?.current?.setFieldValue(
          "integrationtype",
          { label: "Relex", id: 2 }
        );
      }
      else{
        setShowBackupprefix(false)
        formRef?.current?.setFieldValue(
          "integrationtype",
          { label: "Select", id: 1 }
        );
      }
    
      formRef?.current?.setFieldValue(
        "tenantid",
        formDetails?.externalBlobSettings?.tenantId
      );
      formRef?.current?.setFieldValue(
        "clientid",
        formDetails?.externalBlobSettings?.clientId
      );
      formRef?.current?.setFieldValue(
        "clientSecret",
        formDetails?.externalBlobSettings?.clientSecret
      );
      if(formDetails?.externalBlobSettings?.isActive == false){
        formRef?.current?.setFieldValue(
          "disableblob",
         true
        );
      }
      else{
        formRef?.current?.setFieldValue(
          "disableblob",
          false
        );
      }
      formRef?.current?.setFieldValue(
        "blobarchiveFilesCheck",
        formDetails?.externalBlobSettings?.shouldArchive
      );
     
      formRef?.current?.setFieldValue(
        "blobEmailList",
        formDetails?.blobEmailLists?.map((email) => email.name)?.join(", ")
      );
    }
    if(formDetails?.functionTypeId == 1){
      formRef?.current?.setFieldValue(
        "source",
        {label: "-- Select --", id: 1}
      );
    }
    if (formDetails?.functionConfigAndEDIXMLMappings?.length > 0) {
      formDetails?.functionConfigAndEDIXMLMappings?.map((item) => {
        if (item?.edixmlColumnId === 1) {
          formRef?.current?.setFieldValue("colShipReq", item?.columnIndex);
          formRef?.current?.setFieldValue(
            "headerNameShipReq",
            item?.headerName
          );
          formRef?.current?.setFieldValue(
            "altFirstHeaderShipReq",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderShipReq",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 2) {
          formRef?.current?.setFieldValue("colReceiptDate", item?.columnIndex);
          formRef?.current?.setFieldValue(
            "headerNameReceiptDate",
            item?.headerName
          );
          formRef?.current?.setFieldValue(
            "altFirstHeaderReceiptDate",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderReceiptDate",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 3) {
          formRef?.current?.setFieldValue("colShipTo", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameShipTo", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderShipTo",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderShipTo",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 4) {
          formRef?.current?.setFieldValue("colPO", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNamePO", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderPO",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderPO",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 5) {
          formRef?.current?.setFieldValue("colItem", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameItem", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderItem",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderItem",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 6) {
          formRef?.current?.setFieldValue("colQty", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameQty", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderQty",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderQty",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 7) {
          formRef?.current?.setFieldValue("colUOM", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameUOM", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderUOM",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderUOM",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 8) {
          formRef?.current?.setFieldValue("colDescription", item?.columnIndex);
          formRef?.current?.setFieldValue(
            "headerNameDescription",
            item?.headerName
          );
          formRef?.current?.setFieldValue(
            "altFirstHeaderDescription",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderDescription",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 9) {
          formRef?.current?.setFieldValue("colRoute", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameRoute", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderRoute",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderRoute",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 10) {
          formRef?.current?.setFieldValue("colStop", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameStop", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderStop",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderStop",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 11) {
          formRef?.current?.setFieldValue("colWRHS", item?.columnIndex);
          formRef?.current?.setFieldValue("headerNameWRHS", item?.headerName);
          formRef?.current?.setFieldValue(
            "altFirstHeaderWRHS",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderWRHS",
            item?.alternateHeaderName2
          );
        } else if (item?.edixmlColumnId === 12) {
          formRef?.current?.setFieldValue("colInvBatchID", item?.columnIndex);
          formRef?.current?.setFieldValue(
            "headerNameInvBatchID",
            item?.headerName
          );
          formRef?.current?.setFieldValue(
            "altFirstHeaderInvBatchID",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderInvBatchID",
            item?.alternateHeaderName2
          );
        } else {
          formRef?.current?.setFieldValue(
            "colPurchaseOrder",
            item?.columnIndex
          );
          formRef?.current?.setFieldValue(
            "headerNamePurchaseOrder",
            item?.headerName
          );
          formRef?.current?.setFieldValue(
            "altFirstHeaderPurchaseOrder",
            item?.alternateHeaderName1
          );
          formRef?.current?.setFieldValue(
            "altSecondHeaderPurchaseOrder",
            item?.alternateHeaderName2
          );
        }
      });
      formRef?.current?.setFieldValue("keywords", formDetails?.keywords);
    }
    formRef?.current?.setFieldValue(
      "fileStartsWith",
      formDetails?.ftpSettings?.fileNameStartsWith
    );
    formRef?.current?.setFieldValue(
      "userId",
      formDetails?.ftpSettings?.ftpUserName
    );
    formRef?.current?.setFieldValue("site", formDetails?.ftpSettings?.ftpSite);
    formRef?.current?.setFieldValue(
      "password",
      formDetails?.ftpSettings?.ftpPassword
    );
    formRef?.current?.setFieldValue(
      "sourceDir",
      formDetails?.ftpSettings?.ftpSourcePath
    );
    formRef?.current?.setFieldValue(
      "ediArchDir",
      formDetails?.ftpSettings?.ftpArchivePath
    );
    formRef?.current?.setFieldValue(
      "archiveFilesCheck",
      formDetails?.ftpSettings?.shouldArchive
    );
    if(formDetails?.ftpSettings?.isActive !== undefined){
      formRef?.current?.setFieldValue(
        "disableftp",
        !formDetails?.ftpSettings?.isActive
      );
    }
   
    formRef?.current?.setFieldValue(
      "ftpEmailList",
      formDetails.ftpEmailList?.map((email) => email.name)?.join(", ")
    );
  };

  const commonValidation = YUP.mixed().nullable();
  const emailListValidation = YUP.array()
    .transform((str) => str.split(",").map((email) => email.trim()))
    .of(YUP.string().email("Please enter a valid email address"));

  const stringValidation = (maxLength, field) =>
    YUP.string()
      .nullable()
      .required(field + " is required")
      .max(maxLength);

  const numberValidation = (maxValue) =>
    YUP.number().nullable().positive().max(maxValue);

  const validationSchema = YUP.object().shape({
    status: commonValidation.required("Status is required"),
    region: commonValidation.required("Region is required"),
    customer: commonValidation.required("Customer is required"),
    function: YUP.string().nullable().required("Function is required").max(100),
    mapToUse: commonValidation.required("Map to use is required"),
    poMode: commonValidation.required("PO Mode is required"),
    poNum: showPoNumberField
    && YUP.string()
        .required("PO Number is required")
        .min(5, "PO Number must be at least 5 characters")
        .max(50, "PO Number must be less than 50 characters"),
    timezone: isTimeZoneMandatory && commonValidation.required("Time Zone is required") ,
    emailList: emailListValidation,
    maxQuantity: commonValidation,
    offShipdays: commonValidation,
    allowedPasteDays: commonValidation,
    warningDays: commonValidation,
    tolerance: numberValidation(100),
    offsetreceiptDays: commonValidation,
    ediextraComment: YUP.string().nullable().max(100),
    storeInHeaderRow: numberValidation(50),
    storeInHeaderColumn: numberValidation(50),
    storeInHeaderLabel: YUP.string().nullable().max(50),
    dateInHeaderRow: numberValidation(50),
    dateInHeaderColumn: numberValidation(50),
    dateInHeaderLabel: YUP.string().nullable().max(50),
    poInHeaderRow: numberValidation(50),
    poInHeaderColumn: numberValidation(50),
    poInHeaderLabel: YUP.string().nullable().max(50),
    dunnPrefix: YUP.string().nullable().max(50),
    dunnSufix: YUP.string().nullable().max(50),
    itemXref: commonValidation,
    defaultWRHS: YUP.string().nullable().max(50),
    tabularHeaderStartsRow: YUP.number().nullable().max(1000),
    keywords: YUP.string().nullable().max(100),
    headerNameShipReq: YUP.string().nullable().max(50),
    headerNameReceiptDate: YUP.string().nullable().max(50),
    headerNameShipTo:YUP.string().nullable().max(50),
    headerNamePO: YUP.string().nullable().max(50),
    headerNameItem: YUP.string().nullable().max(50),
    headerNameQty: YUP.string().nullable().max(50),
    headerNameUOM: YUP.string().nullable().max(50),
    headerNameDescription:YUP.string().nullable().max(50),
    headerNameRoute: YUP.string().nullable().max(50),
    headerNameStop: YUP.string().nullable().max(50),
    headerNameWRHS: YUP.string().nullable().max(50),
    headerNameInvBatchID: YUP.string().nullable().max(50),
    headerNamePurchaseOrder: YUP.string().nullable().max(50),
    altFirstHeaderShipReq: YUP.string().nullable().max(50),
    altFirstHeaderReceiptDate: YUP.string().nullable().max(50),
    altFirstHeaderShipTo: YUP.string().nullable().max(50),
    altFirstHeaderPO: YUP.string().nullable().max(50),
    altFirstHeaderItem: YUP.string().nullable().max(50),
    altFirstHeaderQty: YUP.string().nullable().max(50),
    altFirstHeaderUOM: YUP.string().nullable().max(50),
    altFirstHeaderDescription: YUP.string().nullable().max(50),
    altFirstHeaderRoute: YUP.string().nullable().max(50),
    altFirstHeaderStop: YUP.string().nullable().max(50),
    altFirstHeaderWRHS: YUP.string().nullable().max(50),
    altFirstHeaderInvBatchID: YUP.string().nullable().max(50),
    altFirstHeaderPurchaseOrder: YUP.string().nullable().max(50),
    altSecondHeaderShipReq: YUP.string().nullable().max(50),
    altSecondHeaderReceiptDate: YUP.string().nullable().max(50),
    altSecondHeaderShipTo: YUP.string().nullable().max(50),
    altSecondHeaderPO: YUP.string().nullable().max(50),
    altSecondHeaderItem: YUP.string().nullable().max(50),
    altSecondHeaderQty: YUP.string().nullable().max(50),
    altSecondHeaderUOM: YUP.string().nullable().max(50),
    altSecondHeaderDescription: YUP.string().nullable().max(50),
    altSecondHeaderRoute: YUP.string().nullable().max(50),
    altSecondHeaderStop:YUP.string().nullable().max(50),
    altSecondHeaderWRHS: YUP.string().nullable().max(50),
    altSecondHeaderInvBatchID:YUP.string().nullable().max(50),
    altSecondHeaderPurchaseOrder: YUP.string().nullable().max(50),
    fileStartsWith: YUP.string().nullable().max(50),
    site: YUP.string().nullable().max(50),
    userId: YUP.string().nullable().max(20),
    password: YUP.string().nullable().max(20),
    sourceDir: YUP.string().nullable().max(50),
    ediArchDir: YUP.string().nullable().max(50),
    storageAccName: YUP.string().nullable().max(50),
    storageContName: YUP.string().nullable().max(50),
    pathToFiles: YUP.string().nullable().max(50),
    archiveContainerName: YUP.string().nullable().max(50),
    blobArchPath: YUP.string().nullable().max(50),
    fileNamePrefix: YUP.string().nullable().max(50),
    clientid: YUP.string().nullable().max(50),
    clientSecret: YUP.string().nullable().max(50),
    tenantid: YUP.string().nullable().max(50),
  });

  const navigateToFunctionList = () => {
    props?.alterComponent(true);
  };

  const testConnection = async (formRef) => {
    try {
      setShowSpinner(true);
      let result = "";
      let ftp = formRef?.current?.values?.source?.id == 2 ? true : false
      if(formRef?.current?.values?.source?.id == 2){
        const data = {
          ftpSite: formRef?.current?.values?.site,
          ftpUserName: formRef?.current?.values?.userId,
          ftpPassword: formRef?.current?.values?.password,
          ftpSourcePath: formRef?.current?.values?.sourceDir,
          ftpArchivePath: formRef?.current?.values?.ediArchDir,
          fileNameStartsWith: formRef?.current?.values?.fileStartsWith,
          shouldArchive: formRef?.current?.values?.archiveFilesCheck,
          isActive: formRef?.current?.values?.disableftp? false : true,
        };
        if(formRef?.current?.values?.archiveFilesCheck && (formRef?.current?.values?.ediArchDir == null || formRef?.current?.values?.ediArchDir?.trim() == "")){
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Please add EDI Archive Directory path",
            life: 4000
          });
          setShowSpinner(false);
        }
        else{
        result = await FunctionTestConnection(data,ftp);
        setShowSpinner(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: result,
          life: 4000
        });
      }
      }
      else{
        const data = {
          blobAccountName:  formRef?.current?.values?.storageAccName == null? null :  formRef?.current?.values?.storageAccName?.trim() == "" ? null : formRef?.current?.values?.storageAccName?.trim(),
          blobContainerName: formRef?.current?.values?.storageContName == null? null :  formRef?.current?.values?.storageContName?.trim() == "" ? null : formRef?.current?.values?.storageContName?.trim(),
          blobSourcePath:  formRef?.current?.values?.pathToFiles == null? null :  formRef?.current?.values?.pathToFiles?.trim() == "" ? null : formRef?.current?.values?.pathToFiles?.trim(),
          archiveContainerName: formRef?.current?.values?.archiveContainerName == null? null :  formRef?.current?.values?.archiveContainerName?.trim() == "" ? null : formRef?.current?.values?.archiveContainerName?.trim(),
          blobArchivePath:  formRef?.current?.values?.blobArchPath == null? null : formRef?.current?.values?.blobArchPath?.trim() == "" ? null : formRef?.current?.values?.blobArchPath?.trim(),
          shouldArchive:  formRef?.current?.values?.blobarchiveFilesCheck,
          fileNameStartsWith: formRef?.current?.values?.fileNamePrefix == null? null : formRef?.current?.values?.fileNamePrefix?.trim() == "" ? null : formRef?.current?.values?.fileNamePrefix?.trim(),
          shouldBlobDisable:  formRef?.current?.values?.disableblob,
          clientId:  formRef?.current?.values?.clientid == null? null : formRef?.current?.values?.clientid?.trim() == "" ? null : formRef?.current?.values?.clientid?.trim(),
          clientSecret: formRef?.current?.values?.clientSecret == null? null : formRef?.current?.values?.clientSecret?.trim() == "" ? null : formRef?.current?.values?.clientSecret?.trim(),
          tenantId:  formRef?.current?.values?.tenantid == null? null : formRef?.current?.values?.tenantid?.trim() == "" ? null : formRef?.current?.values?.tenantid?.trim(),
        }
        result = await FunctionTestConnection(data,ftp);
        setShowSpinner(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: result,
          life: 4000
        });
      }
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while testing Connection",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const onStatusChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue("status", value);
  };

  const submitFunctionData = async (formbody) => {
    setShowSpinner(true);
    try {
      if(formbody?.source?.id == 2){
        setIsBlobFilled(false);
        if( formbody?.status?.id == 41 && (formbody?.site == null ||
          formbody?.site?.trim() == "" ||
          formbody?.userId == null ||
          formbody?.userId?.trim() == "" ||
          formbody?.password == null ||
          formbody?.password?.trim() == "" ||
          formbody?.sourceDir == null ||
          formbody?.sourceDir?.trim() == "")){
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Please fill Site, User ID, Password and Source Directory!",
              life: 4000
            });
            setIsFTPFilled(true);
            setShowSpinner(false);
            return;
          }
          else if(formbody?.status?.id == 41 &&  formbody?.archiveFilesCheck == true && 
            (formbody?.ediArchDir == null ||
              formbody?.ediArchDir?.trim() == "" ||
              formbody?.site == null ||
              formbody?.site?.trim() == "" ||
              formbody?.userId == null ||
              formbody?.userId?.trim() == "" ||
              formbody?.password == null ||
              formbody?.password?.trim() == "" ||
              formbody?.sourceDir == null ||
              formbody?.sourceDir?.trim() == "") 
          ){
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "Please fill Site, User ID, Password, Source Directory and EDI Archive Directory!",
              life: 4000
            });
            setIsFTPFilled(true);
            setShowSpinner(false);
            return;
          }
      }
      else if(formbody?.source?.id == 3){
        setIsFTPFilled(false);
        if( formbody?.status?.id == 41 && (
          formbody?.storageAccName == null ||
          formbody?.storageAccName?.trim() == "" ||
          formbody?.storageContName == null ||
          formbody?.storageContName?.trim() == "" ||
          formbody?.clientid == null ||
          formbody?.clientid?.trim() == "" ||
          formbody?.clientSecret == null ||
          formbody?.clientSecret?.trim() == "" ||
          formbody?.tenantid == null ||
          formbody?.tenantid?.trim() == ""
        )){
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "Please fill Storage Account Name, Storage Container Name, client id, client secret and tenetid!",
            life: 4000
          });
          setIsBlobFilled(true);
          setShowSpinner(false);
          return;
        }
        if(formbody?.status?.id == 41 && (
          (formbody?.blobarchiveFilesCheck == true && (formbody?.archiveContainerName == null ||  formbody?.archiveContainerName?.trim() == "")) ||
          formbody?.storageAccName == null ||
          formbody?.storageAccName?.trim() == "" ||
          formbody?.storageContName == null ||
          formbody?.storageContName?.trim() == "" ||
          formbody?.clientid == null ||
          formbody?.clientid?.trim() == "" ||
          formbody?.clientSecret == null ||
          formbody?.clientSecret?.trim() == "" ||
          formbody?.tenantid == null ||
          formbody?.tenantid?.trim() == ""
        )){
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "Please fill archive container name!",
            life: 4000
          });
          setIsBlobFilled(true);
          setShowSpinner(false);
          return;
        }
        if(formbody?.status?.id == 41 && (
          (formbody?.integrationtype?.id == 2 && ((formbody?.fileNamePrefix == null ||  formbody?.fileNamePrefix?.trim() == ""))) ||
          formbody?.storageAccName == null ||
          formbody?.storageAccName?.trim() == "" ||
          formbody?.storageContName == null ||
          formbody?.storageContName?.trim() == "" ||
          formbody?.clientid == null ||
          formbody?.clientid?.trim() == "" ||
          formbody?.clientSecret == null ||
          formbody?.clientSecret?.trim() == "" ||
          formbody?.tenantid == null ||
          formbody?.tenantid?.trim() == ""
        )){
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "Please fill File name prefix!",
            life: 4000
          });
          setIsBlobFilled(true);
          setShowSpinner(false);
          return;
        }
      }
      else if(formbody?.source?.id == 1){
        setIsBlobFilled(false);
        setIsFTPFilled(false);
      }
      if(formbody?.status?.id == 41 &&(
        formbody?.suffixPrefix != null && (formbody?.shipToXreflength == null || formbody?.shipToXreflength?.trim() == "")
      )){
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Please fill ShipTo Xref Length",
          life: 4000
        });
        setShowSpinner(false);
        return;
      }
     if (
        (formbody?.status?.id == 40) || (formbody?.status?.id == 42) ||
        (!isFTPFilled && !isBlobFilled && formbody?.status?.id == 41)
      ) {
        const formData = {
          regionId: formbody?.region?.regionId,
          functionName: formbody?.function,
          functionTypeId: formbody?.source?.id ? formbody?.source?.id : 1,
          customerIds: [],
          maptoUseId: formbody?.mapToUse?.fileFormatId,
          poModeId: formbody?.poMode?.poModeId,
          configuredPONumber: formbody?.poMode?.poModeId == 6 ? formbody?.poNum : null,
          doesShipRequestDateUseCurrentDate: formbody?.shipReqDate?.id == 2 ? true  :false,
          timezoneId: formbody?.timezone?.timezoneId,
          statusId: formbody?.status?.id,
          maxQuantity: formbody?.maxQuantity,
          shipDateOffsetDays: formbody?.offShipdays,
          allowedPastDays: formbody?.allowedPasteDays,
          warningDays: formbody?.warningDays,
          tolerance: formbody?.tolerance,
          receiptDateOffsetDays: formbody?.offsetreceiptDays,
          ediExtraComment: formbody?.ediextraComment,
          includeRoutes: formbody?.includeRoutes,
          includeItemXRef: formbody?.includeItemXRef,
          allowNoSalesline: formbody?.allowNoSalesline,
          createPOCSV: formbody?.createPOCheck,
          outboundDirectory: null,
          erp: "D365",
          emailList: [],
          storeInHeaderRow: formbody?.storeInHeaderRow,
          storeInHeaderColumn: formbody?.storeInHeaderColumn,
          storeLabel:
            formbody?.storeInHeaderLabel != "" &&
            formbody?.storeInHeaderLabel != null
              ? formbody?.storeInHeaderLabel
              : null,
          dateInHeaderRow: formbody?.dateInHeaderRow,
          dateInHeaderColumn: formbody?.dateInHeaderColumn,
          dateLabel:
            formbody?.dateInHeaderLabel != "" &&
            formbody?.dateInHeaderLabel != null
              ? formbody?.dateInHeaderLabel
              : null,
          poInHeaderRow: formbody?.poInHeaderRow
            ? formbody?.poInHeaderRow
            : null,
          poInHeaderColumn: formbody?.poInHeaderColumn
            ? formbody?.poInHeaderColumn
            : null,
          poLabel:
            formbody?.poInHeaderLabel != "" && formbody?.poInHeaderLabel != null
              ? formbody?.poInHeaderLabel
              : null,
          loadDescription: formbody?.loadDescription,
          loadWRHSSalesHeader: formbody?.loadWRHS,
          allowDuplicateItems: formbody?.allowDuplicates,
          multiStoreQtyColumns: formbody?.multiStore,
          dunnPrefix:
            formbody?.dunnPrefix != "" && formbody?.dunnPrefix != null
              ? formbody?.dunnPrefix
              : null,
          dunnSuffix:
            formbody?.dunnSufix != "" && formbody?.dunnSufix != null
              ? formbody?.dunnSufix
              : null,
          itemXRefLength: formbody?.itemXref,
          shipToXrefLength:formbody?.shipToXreflength,
          shouldPadZeroesAtStartOfShipTo: formbody?.suffixPrefix?.id == 1 ? true : false,
          shouldPadZeroesAtEndOfShipTo: formbody?.suffixPrefix?.id == 2 ? true : false,
          defaultWRHS:
            formbody?.defaultWRHS != "" && formbody?.defaultWRHS != null
              ? formbody?.defaultWRHS
              : null,
          dataStartRow:
            formbody?.tabularHeaderStartsRow != "" &&
            formbody?.tabularHeaderStartsRow != null
              ? formbody?.tabularHeaderStartsRow
              : 0,
          createdBy: null,
          keywords:
            formbody?.keywords != "" && formbody?.keywords != null
              ? formbody?.keywords
              : null,
          users: null,
          functionConfigurationEDIXMLConfigurationMappings: null,
          ftpSettings: null,
        };
        const data = [
          {
            edixmlColumnId: 1,
            columnIndex: formbody?.colShipReq,
            headerName:
              formbody?.headerNameShipReq != "" &&
              formbody?.headerNameShipReq != null
                ? formbody?.headerNameShipReq
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderShipReq != "" &&
              formbody?.altFirstHeaderShipReq != null
                ? formbody?.altFirstHeaderShipReq
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderShipReq != "" &&
              formbody?.altSecondHeaderShipReq != null
                ? formbody?.altSecondHeaderShipReq
                : null
          },
          {
            edixmlColumnId: 2,
            columnIndex: formbody?.colReceiptDate,
            headerName:
              formbody?.headerNameReceiptDate != "" &&
              formbody?.headerNameReceiptDate != null
                ? formbody?.headerNameReceiptDate
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderReceiptDate != "" &&
              formbody?.altFirstHeaderReceiptDate != null
                ? formbody?.altFirstHeaderReceiptDate
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderReceiptDate != "" &&
              formbody?.altSecondHeaderReceiptDate != null
                ? formbody?.altSecondHeaderReceiptDate
                : null
          },
          {
            edixmlColumnId: 3,
            columnIndex: formbody?.colShipTo,
            headerName:
              formbody?.headerNameShipTo != "" &&
              formbody?.headerNameShipTo != null
                ? formbody?.headerNameShipTo
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderShipTo != "" &&
              formbody?.altFirstHeaderShipTo != null
                ? formbody?.altFirstHeaderShipTo
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderShipTo != "" &&
              formbody?.altSecondHeaderShipTo != null
                ? formbody?.altSecondHeaderShipTo
                : null
          },
          {
            edixmlColumnId: 4,
            columnIndex: formbody?.colPO,
            headerName:
              formbody?.headerNamePO != "" && formbody?.headerNamePO != null
                ? formbody?.headerNamePO
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderPO != "" &&
              formbody?.altFirstHeaderPO != null
                ? formbody?.altFirstHeaderPO
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderPO != "" &&
              formbody?.altSecondHeaderPO != null
                ? formbody?.altSecondHeaderPO
                : null
          },
          {
            edixmlColumnId: 5,
            columnIndex: formbody?.colItem,
            headerName:
              formbody?.headerNameItem != "" && formbody?.headerNameItem != null
                ? formbody?.headerNameItem
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderItem != "" &&
              formbody?.altFirstHeaderItem != null
                ? formbody?.altFirstHeaderItem
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderItem != "" &&
              formbody?.altSecondHeaderItem != null
                ? formbody?.altSecondHeaderItem
                : null
          },
          {
            edixmlColumnId: 6,
            columnIndex: formbody?.colQty,
            headerName:
              formbody?.headerNameQty != "" && formbody?.headerNameQty != null
                ? formbody?.headerNameQty
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderQty != "" &&
              formbody?.altFirstHeaderQty != null
                ? formbody?.altFirstHeaderQty
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderQty != "" &&
              formbody?.altSecondHeaderQty != null
                ? formbody?.altSecondHeaderQty
                : null
          },
          {
            edixmlColumnId: 7,
            columnIndex: formbody?.colUOM,
            headerName:
              formbody?.headerNameUOM != "" && formbody?.headerNameUOM != null
                ? formbody?.headerNameUOM
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderUOM != "" &&
              formbody?.altFirstHeaderUOM != null
                ? formbody?.altFirstHeaderUOM
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderUOM != "" &&
              formbody?.altSecondHeaderUOM != null
                ? formbody?.altSecondHeaderUOM
                : null
          },
          {
            edixmlColumnId: 8,
            columnIndex: formbody?.colDescription,
            headerName:
              formbody?.headerNameDescription != "" &&
              formbody?.headerNameDescription != null
                ? formbody?.headerNameDescription
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderDescription != "" &&
              formbody?.altFirstHeaderDescription != null
                ? formbody?.altFirstHeaderDescription
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderDescription != "" &&
              formbody?.altSecondHeaderDescription != null
                ? formbody?.altSecondHeaderDescription
                : null
          },
          {
            edixmlColumnId: 9,
            columnIndex: formbody?.colRoute,
            headerName:
              formbody?.headerNameRoute != "" &&
              formbody?.headerNameRoute != null
                ? formbody?.headerNameRoute
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderRoute != "" &&
              formbody?.altFirstHeaderRoute != null
                ? formbody?.altFirstHeaderRoute
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderRoute != "" &&
              formbody?.altSecondHeaderRoute != null
                ? formbody?.altSecondHeaderRoute
                : null
          },
          {
            edixmlColumnId: 10,
            columnIndex: formbody?.colStop,
            headerName:
              formbody?.headerNameStop != "" && formbody?.headerNameStop != null
                ? formbody?.headerNameStop
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderStop != "" &&
              formbody?.altFirstHeaderStop != null
                ? formbody?.altFirstHeaderStop
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderStop != "" &&
              formbody?.altSecondHeaderStop != null
                ? formbody?.altSecondHeaderStop
                : null
          },
          {
            edixmlColumnId: 11,
            columnIndex: formbody?.colWRHS,
            headerName:
              formbody?.headerNameWRHS != "" && formbody?.headerNameWRHS != null
                ? formbody?.headerNameWRHS
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderWRHS != "" &&
              formbody?.altFirstHeaderWRHS != null
                ? formbody?.altFirstHeaderWRHS
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderWRHS != "" &&
              formbody?.altSecondHeaderWRHS != null
                ? formbody?.altSecondHeaderWRHS
                : null
          },
          {
            edixmlColumnId: 12,
            columnIndex: formbody?.colInvBatchID,
            headerName:
              formbody?.headerNameInvBatchID != "" &&
              formbody?.headerNameInvBatchID != null
                ? formbody?.headerNameInvBatchID
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderInvBatchID != "" &&
              formbody?.altFirstHeaderInvBatchID != null
                ? formbody?.altFirstHeaderInvBatchID
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderInvBatchID != "" &&
              formbody?.altSecondHeaderInvBatchID != null
                ? formbody?.altSecondHeaderInvBatchID
                : null
          },
          {
            edixmlColumnId: 13,
            columnIndex: formbody?.colPurchaseOrder,
            headerName:
              formbody?.headerNamePurchaseOrder != "" &&
              formbody?.headerNamePurchaseOrder != null
                ? formbody?.headerNamePurchaseOrder
                : null,
            alternativeHeaderName1:
              formbody?.altFirstHeaderPurchaseOrder != "" &&
              formbody?.altFirstHeaderPurchaseOrder != null
                ? formbody?.altFirstHeaderPurchaseOrder
                : null,
            alternativeHeaderName2:
              formbody?.altSecondHeaderPurchaseOrder != "" &&
              formbody?.altSecondHeaderPurchaseOrder != null
                ? formbody?.altSecondHeaderPurchaseOrder
                : null
          }
        ];
        // "users": formbody?.users ? formbody?.users :null,
        const filteredData = data.filter((obj) => {
          const count = Object.values(obj).filter((val) => val !== null).length;
          return count >= 2;
        });
        if (filteredData.length > 0) {
          formData.functionConfigurationEDIXMLConfigurationMappings = [];
          formData?.functionConfigurationEDIXMLConfigurationMappings.push(
            ...filteredData
          );
        }
        if (
          (formbody?.site != null && formbody?.site?.trim() != "") ||
          (formbody?.userId != null && formbody?.userId?.trim() != "") ||
          (formbody?.password != null && formbody?.password?.trim() != "") ||
          (formbody?.sourceDir != null && formbody?.sourceDir?.trim() != "") ||
          (formbody?.ediArchDir != null && formbody?.ediArchDir?.trim() != "") ||
          (formbody?.fileStartsWith != null && formbody?.fileStartsWith?.trim() != "") ||
          (formbody?.archiveFilesCheck != null && formbody?.archiveFilesCheck != false) ||
          (formbody?.disableftp != null && formbody?.disableftp != false)
        ){
          formData.ftpSettings = {
            ftpSite: formbody?.site ? formbody?.site  : null,
            ftpUserName: formbody?.userId ? formbody?.userId : null,
            ftpPassword: formbody?.password? formbody?.password : null,
            ftpSourcePath: formbody?.sourceDir? formbody?.sourceDir : null,
            ftpArchivePath: formbody?.ediArchDir? formbody?.ediArchDir : null,
            fileNameStartsWith: formbody?.fileStartsWith? formbody?.fileStartsWith : null,
            shouldArchive: formbody?.archiveFilesCheck ? formbody?.archiveFilesCheck : false,
            isActive: !formbody?.disableftp,
          }
        }
        if (
          (formbody?.storageAccName != null && formbody?.storageAccName?.trim() != "") ||
          (formbody?.storageContName != null && formbody?.storageContName?.trim() != "") ||
          (formbody?.pathToFiles != null && formbody?.pathToFiles?.trim() != "") ||
          (formbody?.archiveContainerName != null && formbody?.archiveContainerName?.trim() != "") ||
          (formbody?.blobArchPath != null && formbody?.blobArchPath?.trim() != "") ||
          (formbody?.fileNamePrefix != null && formbody?.fileNamePrefix?.trim() != "") ||
          (formbody?.tenantid != null && formbody?.tenantid?.trim() != "") ||
          (formbody?.clientid != null && formbody?.clientid?.trim() != "") ||
          (formbody?.clientSecret != null && formbody?.clientSecret?.trim() != "") ||
          (formbody?.blobarchiveFilesCheck != null && formbody?.blobarchiveFilesCheck != false) ||
          (formbody?.disableblob != null && formbody?.disableblob != false)
        ){
          formData.blobSettings = {
            blobAccountName: formbody?.storageAccName ? formbody?.storageAccName  : null,
            blobContainerName: formbody?.storageContName ? formbody?.storageContName : null,
            blobSourcePath: formbody?.pathToFiles? formbody?.pathToFiles : null,
            archiveContainerName: formbody?.archiveContainerName? formbody?.archiveContainerName : null,
            blobArchivePath: formbody?.blobArchPath? formbody?.blobArchPath : null,
            shouldArchive: formbody?.blobarchiveFilesCheck? formbody?.blobarchiveFilesCheck : false,
            fileNameStartsWith: formbody?.fileNamePrefix? formbody?.fileNamePrefix : null,
            isIntegrationTypeRelex: formbody?.integrationtype?.id ==2 ? true : false,
            backupFileNamePrefix: formbody?.backUpFileNamePrefix? formbody?.backUpFileNamePrefix : null,
            isActive: !formbody?.disableblob,
            clientId: formbody?.clientid? formbody?.clientid : null,
            clientSecret: formbody?.clientSecret? formbody?.clientSecret : null,
            tenantId: formbody?.tenantid? formbody?.tenantid : null,
          }
        }
        if(formbody?.source?.id == 1){
          formData.blobSettings = null;
          formData.ftpSettings = null;
        }
          if (formbody?.users?.length > 0 || formbody?.adminUsers?.length > 0) {
            // formData?.functionConfigurationEDIXMLConfigurationMappings =  filteredData;
            formData.users = [];
            for (let i = 0; i < formbody?.users?.length; i++) {
              formData.users.push({
                userId: formbody.users[i]?.userId,
                roleId: masterDataForFunction?.roles?.filter(
                  (role) => role.name === "FunctionUser"
                )[0]?.roleId
              });
            }
            for (let i = 0; i < formbody?.adminUsers?.length; i++) {
              formData.users.push({
                userId: formbody.adminUsers[i]?.userId,
                roleId: masterDataForFunction?.roles?.filter(
                  (role) => role.name === "FunctionAdmin"
                )[0]?.roleId
              });
            }
          }
        if (formbody?.emailList?.length > 0) {
          formData.emailList = formbody.emailList
            .split(",")
            ?.map((e) => e.trim())
            ?.filter((e) => !!e);
        }
        if (formbody?.ftpEmailList?.length > 0) {
          formData.ftpEmailLists = formbody.ftpEmailList
            .split(",")
            ?.map((e) => e.trim())
            ?.filter((e) => !!e);
        }
        if (formbody?.blobEmailList?.length > 0) {
          formData.blobEmailLists = formbody.blobEmailList
            .split(",")
            ?.map((e) => e.trim())
            ?.filter((e) => !!e);
        }
        if (formbody?.customer?.length > 0) {
          formData.customerIds = [];
          for (let i = 0; i < formbody?.customer?.length; i++) {
            formData.customerIds.push(formbody.customer[i]?.customerId);
          }
        }
        let result = "";
        if (functionId === 0) {
          result = await functionSetup(formData);
          setShowSpinner(false);
        } else {
          result = await functionSetupEdit(formData, functionId);
          setShowSpinner(false);
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: result,
          life: 4000
        });
        setTimeout(() => {
          navigateToFunctionList();
        }, 1000);
      } else {
        setShowSpinner(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill all the FTP settings!",
          life: 4000
        });
      }
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while creating a function",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const tabChange = (index) => {
    setActiveIndex(index);
  };
  const validateForm = (form) => {
    if(form.current?.values?.source?.id == 2){
      if (
        ((form.current?.values?.site == null ||
          form.current?.values?.site?.trim() == "") &&
          (form.current?.values?.userId == null ||
            form.current?.values?.userId?.trim() == "") &&
          (form.current?.values?.sourceDir == null ||
            form.current?.values?.sourceDir?.trim() == "") &&
          (form.current?.values?.password == null ||
            form.current?.values?.password?.trim() == "")) ||
        ((form.current?.values?.site !== null ||
          form.current?.values?.site?.trim() !== "") &&
          (form.current?.values?.userId !== null ||
            form.current?.values?.userId?.trim() !== "") &&
          (form.current?.values?.sourceDir !== null ||
            form.current?.values?.sourceDir?.trim() !== "") &&
          (form.current?.values?.password !== null ||
            form.current?.values?.password?.trim() !== ""))
      ) {
        setIsFTPFilled(false);
      } else {
        setIsFTPFilled(true);
      }
    }
    else{
      if (
        ((form.current?.values?.storageAccName == null ||
          form.current?.values?.storageAccName?.trim() == "") &&
          (form.current?.values?.storageContName == null ||
            form.current?.values?.storageContName?.trim() == "") &&
          (form.current?.values?.pathToFiles == null ||
            form.current?.values?.pathToFiles?.trim() == "")) ||
        ((form.current?.values?.storageAccName !== null ||
          form.current?.values?.storageAccName?.trim() !== "") &&
          (form.current?.values?.storageContName !== null ||
            form.current?.values?.storageContName?.trim() !== "") &&
          (form.current?.values?.pathToFiles !== null ||
            form.current?.values?.pathToFiles?.trim() !== ""))
      ) {
        setIsBlobFilled(false);
      } else {
        setIsBlobFilled(true);
      }
    }

    // if((form.current?.values?.archiveFilesCheck == true ) && (form.current?.values?.ediArchDir == null ||
    //   form.current?.values?.ediArchDir?.trim() == "") ){
    //     toast.current.show({
    //       severity: "error",
    //       summary: "Error",
    //       detail: "Please fill EDI Archive Directory!",
    //       life: 4000
    //     });
    //     setIsFTPFilled(true);
    //     return;
    // }
    setTimeout(() => {
      if (!form?.current?.isValid) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill all the required fields!",
          life: 4000
        });
      }
    }, 200);
  };
  const closeFunctionForm = (form) => {
    if (form?.current?.dirty) {
      setShowConfirmDialog(true);
    } else {
      navigateToFunctionList();
    }
  };
  const accept = () => {
    navigateToFunctionList();
  };

  unstable_usePrompt({
    message:
      "Changes you made may not be saved. Are you sure you want to proceed?",
    when: ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  });
  return (
    <>
      {showSpinner && <Spinner />}
      <div className="grid">
        <div className={`col-12 ${globalStyles.headerContainer}`}>
          <h3><span className={globalStyles.heading}>{pageHeading}</span>
          {!(formState.trim()?.toLocaleLowerCase() === "new") && <><span className={styles?.function_name_detail}>-{functionDetailsByIdData?.functionName}</span></>}</h3>
          <Button
            label="Back to Functions"
            icon="pi pi-arrow-left"
            onClick={navigateToFunctionList}
            className={styles.addFunctionButton}
          />
        </div>
      </div>
      <div>
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setShowSpinner(true);
            submitFunctionData(values);
          }}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit} ref={formRef}>
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Status</span>
                  </label>
                  <Dropdown
                    name="status"
                    placeholder="Select a Status"
                    value={values.status}
                    onChange={(e) => onStatusChange(e, setFieldValue)}
                    options={status}
                    optionLabel="name"
                    disabled={isReadonly}
                    className={styles.statusdropdown}
                  />
                  <div>
                    {errors.status && touched.status && (
                      <span className={styles?.errorMsg}>{errors.status}</span>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    <TabPanel
                      header="General Information"
                      className={`${styles.tab} ${
                        activeIndex === 0
                          ? styles.activeTab
                          : styles.InactiveTab
                      }`}
                    >
                      <GeneralInfoForm
                        styles={styles}
                        values={values}
                        changeEvent={handleChange}
                        isReadonly={isReadonly}
                        blurEvent={handleBlur}
                        errors={errors}
                        touched={touched}
                        masterData={masterDataForFunction}
                        tabChange={tabChange}
                        setFieldValue={setFieldValue}
                        showPoNumberField={showPoNumberField}
                        setShowPONumberField={setShowPONumberField}
                        setIsTimeZoneMandatory={setIsTimeZoneMandatory}
                        isTimeZoneMandatory={isTimeZoneMandatory}
                      />
                    </TabPanel>
                    <TabPanel
                      header="User Information"
                      className={`${styles.tab} ${
                        activeIndex === 1
                          ? styles.activeTab
                          : styles.InactiveTab
                      }`}
                    >
                      <UserInfoForm
                        styles={styles}
                        errors={errors}
                        touched={touched}
                        changeEvent={handleChange}
                        values={values}
                        isReadonly={isReadonly}
                        masterData={masterDataForFunction}
                        tabChange={tabChange}
                      />
                    </TabPanel>
                    <TabPanel
                      header="Map File Columns"
                      className={`${styles.tab} ${
                        activeIndex === 2
                          ? styles.activeTab
                          : styles.InactiveTab
                      }`}
                    >
                      <MapFileInfoForm
                        styles={styles}
                        values={values}
                        changeEvent={handleChange}
                        isReadonly={isReadonly}
                        blurEvent={handleBlur}
                        errors={errors}
                        touched={touched}
                        masterData={masterDataForFunction}
                        tabChange={tabChange}
                        setFieldValue={setFieldValue}
                        showFtp={showFtp}
                      />
                    </TabPanel>
                    {showFtp &&<TabPanel
                      header="Automation settings"
                      className={`${styles.tab} ${
                        activeIndex === 3
                          ? styles.activeTab
                          : styles.InactiveTab
                      }`}
                    >
                       <FTPForm
                        styles={styles}
                        values={values}
                        changeEvent={handleChange}
                        isReadonly={isReadonly}
                        blurEvent={handleBlur}
                        errors={errors}
                        touched={touched}
                        resetBlob={resetBlob}
                        resetFTP={resetFTP}
                        masterData={masterDataForFunction}
                        tabChange={tabChange}
                        setFieldValue={setFieldValue}
                        setShowBackupprefix={setShowBackupprefix}
                        showBackupPrefix={showBackupPrefix}
                        testConnection={() => testConnection(formRef)}
                        isEdited={ functionId !== 0 &&
                          functionDetailsByIdData?.functionId === functionId &&
                          Object.keys(functionDetailsByIdData).length && !isReadonly}
                      />
                    </TabPanel>}
                  </TabView>
                </div>
                <hr />
                <div
                  className={
                    isReadonly ? ` col-12 ${styles.hideSubmitBtns}` : "col-12"
                  }
                >
                  <Button
                    label="Save"
                    type="submit"
                    size="large"
                    className={styles.primaryBtn}
                    onClick={() => validateForm(formRef)}
                  />
                  <Button
                    label="Cancel"
                    type="button"
                    size="large"
                    className={` ${styles.tirnaryBtn}`}
                    onClick={() => closeFunctionForm(formRef)}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {showConfirmDialog && (
          <ConfirmDialog
            visible={showConfirmDialog}
            onHide={() => setShowConfirmDialog(false)}
            message="Changes you made may not be saved. Are you sure you want to proceed?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            style={{ width: "50vw", color: "red" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
            accept={accept}
          ></ConfirmDialog>
        )}
        <Toast ref={toast} position="center" />
      </div>
    </>
  );
};

export default FunctionForm;
